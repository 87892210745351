
.login-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/img/image/teacherloginbj.jpg");
    background-repeat: no-repeat;
    /*background-size: contain;*/
    overflow: auto;
}
.header {
    padding: 20px;
}
.content {
    width: 960px;
    height:520px;
    margin: 40px auto;
}
.left {
    width: 100%;
    height: 100%;
    float: left;
    background-image: url("../../assets/img/image/teacherbj.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 4px 0 0 4px;
}
.right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    float: right;
    background: rgba(255,255,255,.0);
    border-radius: 0 4px 4px 0;

}
.right .el-input{
    width: 340px;
    border:1px solid rgba(177,179,204,1);
    border-radius:4px
}
.login-form {
    width: 400px;
    height:300px;
    margin: 0 auto;
    ::v-deep .el-input__inner {
        border-radius: 0;
        border: none;
        border-bottom: 1px solid #DCDFE6;
        border-radius:4px
    }

    ::v-deep.el-form-item__content{
        margin-left: 30px!important;
    }
    i{
        font-size: 20px;
    }
}
::v-deep.el-form-item{
    margin-bottom: 30px;
}
.el-form{
    background: #FFFFFF;
}
.title {

    margin-bottom:30px;
}
.title span {
    font-size: 30px;
    color: #5794FF;
    margin-left: 30px;
}
.title p{
    color: #333333;
    font-size: 18px;
    font-weight: 400;
    margin-left: 30px;
}
.admin-btn{
    width:340px;
    /*margin-left: 68px;*/
    margin-top: 30px;
    background:rgba(87,148,255,1);
    border-radius:20px;
    border: none;
    margin-left: 30px;
}
